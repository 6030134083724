.base {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-size: var(--dui-font-size-medium);

    --color-background-mapbox-controls: rgba(255, 255, 255, 0.6);
    --color-nlp-tag: #5f54ab;

    .navbar {
        flex-shrink: 0;
        height: 64px;

        &:not(.hidden) {
            display: flex;
        }

        &.hidden {
            display: none;
        }
    }

    .init {
        flex-grow: 1;
        overflow: auto;
    }

    .view {
        flex-grow: 1;
        overflow: auto;
    }

    .alert-container {
        position: fixed;
        right: var(--dui-spacing-large);
        bottom: var(--dui-spacing-large);
        z-index: 1;
        width: 24rem;
    }

    .monday-help-button {
        position: fixed;
        bottom: var(--dui-spacing-extra-large);
        left: 0;
        transform: rotate(90deg) translateX(-100%);
        transform-origin: 0 100%;
        border: var(--dui-width-separator-thin) solid var(--dui-color-separator);
        border-bottom: 0;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        background-color: var(--dui-color-surface-information);
        padding: 0 var(--dui-spacing-small);
        font-size: var(--dui-font-size-extra-small);
        font-weight: var(--dui-font-weight-regular);
    }

    .form-overlay {
        display: flex;
        position: fixed;
        bottom: var(--dui-spacing-large);
        left: var(--dui-spacing-large);
    }

    .form-content {
        position: relative;
        z-index: 1000;
        border: var(--dui-width-separator-thin) solid var(--dui-color-separator);
        border-radius: var(--dui-border-radius-card);
        background-color: var(--dui-color-white);
    }

    .form-top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-left-radius: var(--dui-border-radius-card);
        border-top-right-radius: var(--dui-border-radius-card);
        background-color: var(--dui-color-accent);
        padding: var(--dui-spacing-extra-small) var(--dui-spacing-medium);
        width: 100%;
        color: var(--dui-color-white);
    }

    .iframe {
        flex-grow: 1;
        border: none;
        width: 330px;
        height: 490px;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
