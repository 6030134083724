/*
NOTE: naming keyframes as wiggle-vertically will
result in `postcss-modules/no-unused-class`
warning. very weird!
 */
@keyframes wiggle {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes wave {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(1.2);
    }
    100% {
        transform: scaleX(1);
    }
}

.error-page{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 0fr;
    overflow: auto;

    .background-container {
        overflow: hidden;
        grid-area: 1 / 1 / 1 / 1;

        .background-image {
            margin-top: 160px;
            width: 100%;
            height: 100%;
            animation: wave 4s linear infinite;
            object-fit: cover;
            object-position: top center;
        }
    }

    .container {
        margin: 0 auto;
        padding: var(--dui-spacing-extra-large);
        width: 100%;
        max-width: 1200px;
        grid-area: 1 / 1 / 1 / 1;

        .logo-container {
            display: flex;
            align-items: center;
            flex-shrink: 0;

            .logo {
                height: var(--dui-font-size-mega-large);
                color: var(--dui-color-brand);
            }
        }

        .content {
            display: flex;
            position: relative;
            flex-direction: column;
            gap: var(--dui-spacing-extra-large);

            .kraken {
                align-self: flex-end;
                animation: wiggle 4s 1s linear infinite;
                --height: 24rem;
            }

            .message {
                display: flex;
                align-self: flex-start;
                flex-direction: column;
                color: var(--dui-color-brand);
                font-weight: var(--dui-font-weight-bold);

                .error-message-title {
                    margin: 0;
                    font-size: var(--dui-font-size-ultra-large);
                }

                .error-message {
                    line-height: 2;
                    font-size: var(--dui-font-size-extra-large);
                }
            }

            .buttons {
                display: flex;
                gap: var(--dui-spacing-medium);
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--dui-color-foreground);
        padding: var(--dui-spacing-medium);
        max-height: 100%;
    }
}

