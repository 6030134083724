.action-buttons-row {
    display: flex;
    justify-content: flex-end;

    .action-button {
        flex-shrink: 0;

        &:not(:first-child) {
            margin-left: var(--dui-spacing-medium);
        }
    }
}
