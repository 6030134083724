.avatar {
    --size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);

    .image {
        border: var(--width-separator-thin) solid var(--color-separator);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border: var(--width-separator-thin) solid var(---color-separator);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: calc(0.6 * var(--size));
    }

    .default-user {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        overflow: hidden;

        path {
            fill: var(--color-text);
        }
    }
}
